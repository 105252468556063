@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.about-section {
  margin-bottom: spacing.$l;

  &__details {
    border-radius: corner-radius.$m;
    background-color: light.$surface-primary-default;
    margin-top: spacing.$m;
    padding: spacing.$m;
  }

  &__title {
    margin-bottom: spacing.$s;
  }

  &__member-details {
    display: flex;
    gap: spacing.$xs;
    margin-top: spacing.$s;
  }

  &__icon {
    margin-top: spacing.$xxs;
  }

  &__detail-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__form-buttons {
    display: flex;
    gap: spacing.$xxs;
  }
}
